import { Storage } from 'aws-amplify'

export async function s3Upload(fileName, file) {
  const { key } = await Storage.put(fileName, file, {
    contentType: file.type,
    level : 'public'
  })
  return key
}

export async function s3Get(key) {
  try {
    return `${process.env.REACT_APP_ASSET_URL}/${key}`
  } catch (error) {
    throw error
  }
}

export async function s3GetPrivate(key) {
  console.log(key,"s3 get ni key")
  try {
    const url = await Storage.get(key, { level : 'public' })
    console.log('url::',url)
    return url
  } catch (error) {
    throw error
  }
}
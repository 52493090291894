import apiClient from 'services/axios'
import store from 'store'
import config from '../../config'

// const BASEURL = `https://yk9s7at863.execute-api.ca-central-1.amazonaws.com/dev/`
const BASEURL = config.apiUrl

export function getUser(option) {
  let query
  if (option.id) {
    query = `?id=${option.id}`
  } else if (option.cognitoid) {
    query = `?cognitoid=${option.cognitoid}`
  } else {
    query = null
  }
  return apiClient
    .get(`${BASEURL}user${query}`, { headers: { Authorization: `Bearer ${store.get('authToken')}` } })
    .then((response) => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch((err) => console.log(err))
}

export async function currentAccount() {
  return apiClient
    .get('/auth/account', { headers: { Authorization: `Bearer ${store.get('authToken')}` } })
    .then((response) => {
      if (response) {
        const { accessToken } = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        return response.data
      }
      return false
    })
    .catch((err) => console.log(err))
}

export function getAllUsers(option) {
  let url = `${BASEURL}users`
  const keylist = Object.keys(option)
  if (keylist.length > 0) {
    url = url.concat('?');
    keylist.forEach((key, index) => {
      url = url.concat(`${key}=${option[key]}`);
      if (index < keylist.length - 1) {
        url = url.concat('&');
      }
    });
  }
  return apiClient
    .get(`${url}`, { headers: { Authorization: `Bearer ${store.get('authToken')}` } })
    .then((response) => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch((err) => console.log(err))
}

export function updateUserRole(companyEmployeeId, payload) {
  return apiClient
    .put(`${BASEURL}companyemployee/${companyEmployeeId}`, payload, {
      headers: { Authorization: `Bearer ${store.get('authToken')}` },
    })
    .then((response) => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch((err) => console.log(err))
}

export function getCompanyEmployeeByRole(companyId, userTypes) {
  try {
    const userRoles = userTypes.join(' ')
    console.log('userRoles: ', userRoles)
    return apiClient
      .get(`${BASEURL}companyemployee?companyid=${companyId}&types=${userRoles}`, {
        headers: { Authorization: `Bearer ${store.get('authToken')}` },
      })
      .then((response) => {
        console.log('response: ', response)
        if (response) {
          return response.data
        }
        return false
      })
      .catch((err) => console.log(err))
  } catch (err) {
    throw err
  }
}

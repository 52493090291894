const {
	REACT_APP_COGNITO_REGION,
	REACT_APP_COGNITO_USER_POOL_ID,
	REACT_APP_COGNITO_IDENTITY_POOL_ID,
	REACT_APP_COGNITO_APP_CLIENT_ID,
	REACT_APP_S3_REGION,
	REACT_APP_STAGE,
	REACT_APP_S3_BUCKET,
	REACT_APP_S3_ASSET_FOLDER,
	REACT_APP_ASSET_URL,
	REACT_APP_API_URL,
	REACT_APP_API_URL_OLD,
	REACT_APP_MEET_BASE_URL,
	REACT_APP_REGION,
	REACT_APPSYNC_ENDPOINT,
	REACT_APPSYNC_KEY
} = process.env

export default {
	cognito: {
		REGION: REACT_APP_COGNITO_REGION,
		USER_POOL_ID: REACT_APP_COGNITO_USER_POOL_ID,
		IDENTITY_POOL_ID: REACT_APP_COGNITO_IDENTITY_POOL_ID,
		APP_CLIENT_ID: REACT_APP_COGNITO_APP_CLIENT_ID,
	},
	s3: {
		REGION: REACT_APP_S3_REGION,
		BUCKET: REACT_APP_S3_BUCKET,
		ASSET_FOLDER: REACT_APP_S3_ASSET_FOLDER,
	},
	graphql:{
		app_region : REACT_APP_REGION,
		endpoint : REACT_APPSYNC_ENDPOINT,
		key : REACT_APPSYNC_KEY
	},
	assetUrl: REACT_APP_ASSET_URL,
	apiUrl: REACT_APP_API_URL,
	apiUrl_old: REACT_APP_API_URL_OLD,
	stage: REACT_APP_STAGE,
	ONRX_API: {
		apiGateway: {
			REGION: 'ca-central-1',
			URL: REACT_APP_API_URL,
		},
	},
	// JWTSECRATE: 'mediasoupisbest',
	// MediaSoupUrl: 'https://dev.stream.onrx.ca',
	// wssUrl: "wss://meetingserver.yqgtech.com/?connection=",
	// iceServer: { iceServers: [{ urls: ["stun:stun.l.google.com:19302"] }, { urls: ["turn:dev.turn.onrx.ca:3478"], username: "uhc", credential: "Hhands@12345" }, { urls: ["stun:dev.stun.onrx.ca:3478"], username: "uhc", credential: "Hhands@12345" }] }
	MediaSoupUrl: `https://stream.dev.onrx.ca`,
	JWTSECRATE: 'mediasoupisbest',
	meetingAppId: 'yqgpros_demo',
	wssUrl: 'wss://meetingserver.dev.onrx.ca/?connection=',
	//meetBaseURL: REACT_APP_MEET_BASE_URL,
	iceServer: {
		iceServers: [
			{ urls: ['stun:stun.l.google.com:19302'] },
			{ urls: ['turn:turn.dev.onrx.ca:3478'], username: 'uhc', credential: 'Hhands@12345' },
			{ urls: ['stun:stun.dev.onrx.ca:3478'], username: 'uhc', credential: 'Hhands@12345' },
		],
	},
}

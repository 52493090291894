/* eslint-disable react/style-prop-object */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect, useSelector } from 'react-redux'
import { UserOutlined } from '@ant-design/icons'
import moment from 'moment'
import { Link, withRouter, useHistory } from 'react-router-dom'
import { useIdleTimer } from 'react-idle-timer'
import { Menu, Dropdown, Avatar, Button, Modal } from 'antd'
import _ from 'lodash'
import styles from './style.module.scss'
import actions from '../../../../redux/doctor/actions'
import actionsC from '../../../../redux/chat/actions'

const mapStateToProps = ({ user }) => ({ user })

const colorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae']

const ProfileMenu = ({ dispatch, user, userRoles, userData }) => {
	const history = useHistory()
	const { selectedRole, name, profilePicture } = useSelector((state) => state.user)
	const userName = `${name}`
	const [open, setOpen] = useState(false)
	const [userRole, setUserRole] = useState([])

	const LoggedInUserName = `${user.firstName} ${user.lastName}`

	useEffect(() => {
		setUserRole(userData.companyEmployee)
	}, [])


	const logout = () => {
		dispatch({ type: actions.SET_DOCTOR_NAME, payload: null })
		dispatch({ type: actions.SET_DOCTOR_RECORD_ID, payload: null })
		dispatch({ type: actions.SET_DOCTOR_INFO, payload: null })
		dispatch({ type: actionsC.SET_CONVERSATION, payload: null })
		dispatch({
			type: 'user/LOGOUT',
		})
		localStorage.clear()
	}

	const setUserRoleBasedOnSelecttion = (ID) => {
		console.log(ID)

		const filteredCompany = userRole.filter((roles) => {
			return roles.ID == ID
		})

		if (filteredCompany.length) {
			userData.selectedRole = filteredCompany[0]
			dispatch({
				type: 'user/SET_STATE',
				payload: {
					menuData: userData,
				},
			})

			history.push('/')
		}
	}

	// const changeUserAccount = (values) => {
	// 	userData.selectedRole = values
	// 	dispatch({
	// 		type: 'user/SET_STATE',
	// 		payload: {
	// 			menuData: userData,
	// 		},
	// 	})
	// 	history.push('/dashboard')
	// }
	const onIdle = () => {
		// Close Modal Prompt
		// Do some idle action like log out your user
		logout()
		setOpen(true)
		console.log('IDLE ++++++++++', moment().format())
	}
	const onPrompt = () => {
		// Fire a Modal Prompt

		console.log('onPrompt ++++++++++', moment().format())
	}
	const onActive = (event) => {
		// Close Modal Prompt
		// Do some active action

		idleTimer.reset()
		console.log('ACTIVE ++++++++++', moment().format())
	}

	const showModal = () => {
		setOpen(true)
	}
	const handleOk = () => {
		logout()
		setOpen(true)
	}

	const idleTimer = useIdleTimer({ onIdle, onPrompt, onActive, timeout: 1000 * 60 * 30 })
	const menu = (
		<Menu selectable={false} style={{ width: 330 }} expandIcon={false}>
			<div className="row">
				<div className="col-md-12 text-center">
					<div className="mt-4 col-md-12">
						{selectedRole?.CompanyDetails?.companylogo?.length ? (
							<Avatar
								src={`${process.env.REACT_APP_ASSET_URL}/${selectedRole?.CompanyDetails?.companylogo}`}
								shape="round"
								size="large"
							/>
						) : (
							<Avatar
								style={{ backgroundColor: colorList[_.random(5)], verticalAlign: 'middle' }}
								shape="round"
								size="large"
							>
								{user?.name?.charAt(0)}
							</Avatar>
						)}
					</div>
					<div className="row">
						<div className="mt-2 col-md-3"></div>
						<div className="mt-2 col-md-6">
							<strong>{userName || user?.name || 'Anonymous'}</strong>
						</div>
						<div className="mt-2 col-md-3"></div>
					</div>
					
					<div className="row">
						<div className="mt-2 col-md-3"></div>
						<div className="mt-1 col-md-6">
							<p className="align-self-center">
								{_.upperCase(selectedRole?.CompanyDetails?.Name) || 'Not Assigned'}
							</p>
						</div>
						<div className="mt-2 col-md-3"></div>
					</div>
					

					<div className="mt-2 col-md-12" style={{ textAlign: "center" }}>
  <p className="align-self-center mx-auto mx-md-0">{user?.email || 'Not Updated'}</p>
</div>
					{/* <div className="mt-2 col-md-12">
            <button className="btn btn-primary">{selectedRole?.role}</button>
          </div> */}
				</div>
			</div>
			<Menu.Item />

			{selectedRole.role === 'SUPERUSER' ? <Menu.Divider /> : null}

			{selectedRole.role === 'SUPERUSER' ? (
				<Menu.Item>
					<a
						href="#"
						onClick={(e) => {
							e.preventDefault()
							history.push('/Users')
						}}
					>
						<i className="fe fe-user mr-2" />
						Manage Users
					</a>
				</Menu.Item>
			) : null}

			{selectedRole.role === 'SUPERUSER' ? <Menu.Divider /> : null}

			{selectedRole.role === 'SUPERUSER' ? (
				<Menu.Item>
					<a
						href="#"
						onClick={(e) => {
							e.preventDefault()
							history.push('/userstats')
						}}
					>
						<i className="fe fe-user mr-2" />
						Usage Stats
					</a>
				</Menu.Item>
			) : null}
			<Menu.Divider />
			<Menu.Item>
				<a
					href="#"
					onClick={(e) => {
						e.preventDefault()
						history.push('/editUserProfile')
					}}
				>
					<i className="fe fe-user mr-2" />
					<FormattedMessage id="topBar.profileMenu.editProfile" />
				</a>
			</Menu.Item>
			<Menu.Divider />
			<Menu.Item>
				<a
					href="#"
					onClick={(e) => {
						e.preventDefault()
						history.push('/changePassword')
					}}
				>
					<i className="fe fe-user mr-2" />
					Change Password
				</a>
			</Menu.Item>
			<Menu.Divider />
			{userData.companyEmployee.length &&
				userData.companyEmployee.map((roles, index) => {
					console.log("selectedrole", selectedRole, roles );
					if (roles.role !== 'PATIENT' && roles.ID != selectedRole.ID) {
						return (
							<>
								<Menu.Item key={roles.ID} > {roles?.CompanyDetails?.Name} -
									<Button
										key={roles.ID}
										shape="round"
										size="small"
										onClick={() => {
											setUserRoleBasedOnSelecttion(roles.ID)
										}}
									>
										{roles.role}
									</Button>
								</Menu.Item>
							</>
						)
					}
					return null
				})}
			<Menu.Divider />
			<Menu.Item>
				<div className="row">
					<div className="col-md-6 offset-md-3 text-center">
						<Button onClick={logout} style={{ width: '120px', alignSelf: 'center' }}>
							<i className="fe fe-log-out mr-2" />
							<FormattedMessage id="topBar.profileMenu.logout" />
						</Button>
					</div>
				</div>
			</Menu.Item>
		</Menu>
	)
	return (
		<>
			<Dropdown overlay={menu} trigger={['click']}>
				<div style={{ marginLeft: '4px' }}>
					<Button>
						<div className="row">
							<div>
								<p className="align mb-n1 ml-6" style={{ fontSize: '13px', marginTop: '-4px' }}>
									{selectedRole?.role === 'DOCTOR' ? 'Dr.' : null} {LoggedInUserName}
								</p>
								<strong className="ml-4  mt-2 text-center mr-2">
									{_.upperCase(selectedRole?.CompanyDetails?.Name) || ''}
								</strong>
								<p
									className="align mb-n3 ml-3"
									style={{
										marginTop: '-6px',
									}}
								>
									{selectedRole?.role}
								</p>
							</div>
							<div>
								<img
									className={`mr-3 ml-2 mt-2 ${styles.avatar}`}
									style={{ width: 40, height: 40, borderRadius: 100 }}
									src={
										profilePicture
											? `${process.env.REACT_APP_ASSET_URL}/${profilePicture}`
											: 'resources/images/avatars/avatar.png'
									}
									alt=""
								/>
							</div>
						</div>
					</Button>
				</div>
			</Dropdown>
			<Modal title="Inactivity Warning !!" centered visible={open} open={open} onOk={handleOk}>
				<p>
					Looks like you have been inactive for some time, you will be logged out if you do not use
					the application soon.
				</p>
			</Modal>
		</>
	)
}

export default connect(mapStateToProps)(ProfileMenu)

/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
import { CaretDownOutlined, CheckOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons'
import {
    Button,
    List,
    Modal,
    Select,
    Spin,
    Switch,
    Tabs,
    Tooltip,
    Typography,
    notification
} from 'antd'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { FaClinicMedical, FaHome } from 'react-icons/fa'
import { HiOutlineOfficeBuilding } from 'react-icons/hi'
import { RiAddFill } from 'react-icons/ri'
import { connect, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import actions from '../../../redux/company/actions'
import docotorActions from '../../../redux/doctor/actions'
import { GET, UPDATEDGET, UPDATEDPOST, UPDATEDPUT } from '../../../services/axios/common.api'
import UserMenu from './UserMenu/index'
import style from './style.module.scss'
import { isMobile, isTablet, isBrowser } from 'react-device-detect';


const mapStateToProps = (state) => ({
    selectedClinicInfo: state.selectedClinicInfo,
    selectedCompanyInfo: state.selectedCompanyInfo,
})

const { Option } = Select
const { TabPane } = Tabs

const TopBar = ({ dispatch }) => {
    const { Text } = Typography
    const { selectedCompanyInfo, name: loginUserName } = useSelector((state) => state.user)
    const history = useHistory()
    const { companyEmployee, selectedRole } = useSelector((state) => state.user)
    const userData = useSelector((state) => state.user)
    const [, setOptions] = useState([])
    const [clinicList, setClinicList] = useState([])
    const [companyList, setCompanyList] = useState([])
    const [assistedLiving, setAssistedLiving] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const [roleModal, setRoleModal] = useState(false)
    const [selectedTab, setSelectedTab] = useState('clinic')
    const [modalLoader, setModalLoader] = useState(false)
    const [userRole, setUserRole] = useState('')
    const [availableForWalkin, availableForWalking] = useState(companyEmployee[0].availforwalkin)
    const [updateWalkingAvaibilityLoader, setUpdateWalkingAvaibilityLoader] = useState(false)
    const [pharmacyList, setPharmacyList] = useState([])
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

    console.log('selectedRole--->',selectedRole);
    console.log('companyEmployee--->',companyEmployee);
    useEffect(() => {
        console.log("topbar");
        console.log(companyEmployee)
        console.log("topbar1");
        console.log(selectedRole);



        if (companyEmployee && companyEmployee.length) {
            const roles = companyEmployee.map((roleInfo) => {
                return (
                    <>
                        <Option key={roleInfo.id} value={roleInfo.id}>
                            {roleInfo.role}
                        </Option>
                        {setUserRole(roleInfo.role)}
                    </>
                )
            })
            setOptions(roles)
        }
    }, [companyEmployee])

    const logEvents = async (data) => {
        try {
            let result = await UPDATEDPOST(`log`, data)
        } catch(e) {
            console.log(e, "ERRORRR")
        }
    }

    useEffect(() => {
        setUserRole(userData.selectedRole.role)
    }, [userData])

    const manageAccount = () => {
        history.push('/manageAccount')
    }
    const addAccount = (accountType) => {
        if (accountType === 'clinic') {
            history.push('/addClinic')
        } else if (accountType === 'Assisted Living') {
            history.push('/addAssistedLiving')
        } else if (accountType === 'company') {
            history.push('/addCompany')
        } else {
            history.push('/addPharmacy')
        }
    }

    const onAccountTypeChange = async () => {
        setOpenModal(true)
        setModalLoader(true)
        const tempCompanyList = []
        const tempClinicList = []
        const responseAssistedLivingList = []
        const pharmacyList = []
        let companyAndClinicList = [];



        companyAndClinicList = await UPDATEDGET('company');
        if (companyAndClinicList?.data) {
            companyAndClinicList?.data?.forEach((element) => {
                if (element.type === 'COMPANY') {
                    tempCompanyList.push({
                        name: element.name,
                        id: element.id,
                        address: element.address,
                        email: element.email,
                    })
                } else if (element.type === 'ASSISTEDLIVING') {
                    responseAssistedLivingList.push({
                        name: element.name,
                        id: element.id,
                        address: element.address,
                        email: element.email,
                    })
                } else if (element.type === 'PHARMACY') {
                    pharmacyList.push({
                        name: element.name,
                        id: element.id,
                        address: element.address,
                        email: element.email,
                    })
                } else {
                    tempClinicList.push({
                        name: element.name,
                        id: element.id,
                        address: element.address,
                        email: element.email,
                    })
                }
            })

            setAssistedLiving(responseAssistedLivingList)
            setCompanyList(tempCompanyList)
            setClinicList(tempClinicList)
            setPharmacyList(pharmacyList);

            setModalLoader(false);
        }
    }

    const changeRoleForCurrentUser = () => {
        setRoleModal(true)
    }

    const setUserRoleBasedOnSelecttion = (ID) => {
        const filteredCompany = userData?.companyEmployee?.filter((roles) => {
            return roles.ID === ID
        })

        if (filteredCompany.length) {
            userData.selectedRole = filteredCompany[0]
            dispatch({
                type: 'user/SET_STATE',
                payload: {
                    menuData: userData,
                },
            })
            setUserRole(userData?.selectedRole?.role)
            setRoleModal(false)
        }
    }

    const selectedAccount = async (account) => {
        const selectedCompanyInfos = await _.filter(companyList, (data) => data.id === account.id)[0]
        await dispatch({ type: actions.SET_COMPANY_ID, payload: account.id })
        await dispatch({ type: actions.SET_COMPANY_INFO, payload: selectedCompanyInfos })
        await dispatch({
            type: docotorActions.SET_DOCTOR_INFO,
            payload: {
                id: '',
                companyemployeeid: '',
                type: '',
                firstname: '',
                lastname: '',
                middlename: '',
            },
        })

        console.log('selectedTab === ', selectedTab)

        if (selectedTab === 'clinic' || selectedTab === 'Assisted Living') {
            history.push('/dashboard')
        } else if (selectedTab === 'company') {
            history.push('/company/dashboard')
        } else {
            history.push('/pharmacyDashboard')
        }
        try {

            const { data } = await UPDATEDGET(`company/${account.id}`)
            console.log(data, "company selected data ahiya ave che")
            dispatch({
                type: 'user/SET_STATE',
                payload: {
                    selectedCompanyInfo: data[0],
                },
            })
        } catch (err) {
            notification.error({
                message: 'Something went wrong',
            })
        }
    }
    const switchAccount = async (account) => {
        setSelectedTab(account)
    }

    const changeWalkingInAvailableStatus = async (status) => {

        try {
            userData.companyEmployee[0].availforwalkin = status
            setUpdateWalkingAvaibilityLoader(true)
            UPDATEDPUT(`companyemployee/${companyEmployee[0].ID}`, {
                availforwalkin: status,
            }).then((data) => {
                dispatch({
                    type: 'user/SET_STATE',
                    payload: {
                        menuData: userData,
                    },
                })

                if (data.data.statusCode) {
                    setUpdateWalkingAvaibilityLoader(false)

                    if (status) {
                        notification.info({
                            message: 'Walkin Enabled',
                        })
                    } else {
                        notification.info({
                            message: 'Walkin Disabled',
                        })
                    }
                }
            })
        } catch (error) {
            setUpdateWalkingAvaibilityLoader(false)
            notification.error({
                message: 'Something went wrong',
            })
        }
    }
    return (
        <div>
            {history.location.pathname !== '/selectRole' ? (
                <>
                    <Modal
                        closable={false}
                        visible={openModal}
                        onOk={() => {
                            setOpenModal(false)
                        }}
                        onCancel={() => {
                            setOpenModal(false)
                        }}
                        className="modalselectgroup"
                    >
                        <Tabs defaultActiveKey="1" onChange={switchAccount}>
                            {/* Clinics */}
                            <TabPane tab="Clinics" key="clinic">
                                <div className="tabaction">
                                    <Button
                                        size="middle"
                                        onClick={() => {
                                            addAccount(selectedTab)
                                        }}
                                        className="addnewbtn ant-btn-primary"
                                    >
                                        <RiAddFill style={{ marginRight: '5px' }} />
                                        <span>{`Add ${selectedTab}`}</span>
                                    </Button>
                                </div>
                                <ul className="groupslistings">
                                    {clinicList?.map((list) => (
                                        <List.Item
                                            key={Math.random()}
                                            style={
                                                selectedCompanyInfo.id === list.id ? { backgroundColor: '#e4e5f9' } : null
                                            }
                                        >
                                            <List.Item.Meta
                                                avatar={<FaClinicMedical className="ml-2" size="2em" />}
                                                title={<strong>{list.name}</strong>}
                                                description={list.address}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    selectedAccount(list)
                                                }}
                                            />
                                            <div className="mr-2">{list.email}</div>
                                        </List.Item>
                                    ))}
                                </ul>
                            </TabPane>
                            {/* ASSISTED LIVING */}
                            <TabPane tab="Assisted Living" key="Assisted Living">
                                <div className="tabaction">
                                    <Button
                                        size="middle"
                                        onClick={() => {
                                            addAccount(selectedTab)
                                        }}
                                        className="addnewbtn ant-btn-primary"
                                    >
                                        <RiAddFill style={{ marginRight: '5px' }} />
                                        <span>{`Add ${selectedTab}`}</span>
                                    </Button>
                                </div>
                                <ul className="groupslistings">
                                    {assistedLiving?.map((list) => (
                                        <List.Item
                                            key={Math.random()}
                                            style={
                                                selectedCompanyInfo.id === list.id ? { backgroundColor: '#e4e5f9' } : null
                                            }
                                        >
                                            <List.Item.Meta
                                                avatar={<FaHome className="ml-2" size="2em" />}
                                                title={<strong>{list.name}</strong>}
                                                description={list.address}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    selectedAccount(list)
                                                }}
                                            />
                                            <div className="mr-2">{list.email}</div>
                                        </List.Item>
                                    ))}
                                </ul>
                            </TabPane>
                            {/* COMPANY */}
                            <TabPane tab="Company" key="company">
                                <div className="tabaction">
                                    <Button
                                        size="middle"
                                        onClick={() => {
                                            addAccount(selectedTab)
                                        }}
                                        className="addnewbtn ant-btn-primary"
                                    >
                                        <RiAddFill style={{ marginRight: '5px' }} />
                                        <span>{`Add ${selectedTab}`}</span>
                                    </Button>
                                </div>
                                <ul className="groupslistings">
                                    {!companyList.length > 0 ? (
                                        <div className={style.div_loader}>
                                            <Spin tip="Loading..." indicator={antIcon} spinning={modalLoader} />
                                        </div>
                                    ) : (
                                        companyList?.map((list) => (
                                            <List.Item
                                                key={Math.random()}
                                                style={
                                                    selectedCompanyInfo.id === list.id ? { backgroundColor: '#e4e5f9' } : null
                                                }
                                            >
                                                <List.Item.Meta
                                                    avatar={<HiOutlineOfficeBuilding className="ml-2" size="2em" />}
                                                    title={<strong>{list.name}</strong>}
                                                    style={{ cursor: 'pointer' }}
                                                    description={list.address}
                                                    onClick={() => {
                                                        selectedAccount(list)
                                                    }}
                                                />
                                                <div className="mr-2">{list.email}</div>
                                            </List.Item>
                                        ))
                                    )}
                                </ul>
                            </TabPane>
                            {/* COMPANY */}
                            <TabPane tab="Pharmacy" key="pharmacy">
                                <div className="tabaction">
                                    <Button
                                        size="middle"
                                        onClick={() => {
                                            addAccount(selectedTab)
                                        }}
                                        className="addnewbtn ant-btn-primary"
                                    >
                                        <RiAddFill style={{ marginRight: '5px' }} />
                                        <span>{`Add ${selectedTab}`}</span>
                                    </Button>
                                </div>
                                <ul className="groupslistings">
                                    {!pharmacyList.length > 0 ? (
                                        <div className={style.div_loader}>
                                            <Spin tip="Loading..." indicator={antIcon} spinning={modalLoader} />
                                        </div>
                                    ) : (
                                        pharmacyList?.map((list) => (
                                            <List.Item
                                                key={Math.random()}
                                                style={
                                                    selectedCompanyInfo.id === list.id ? { backgroundColor: '#e4e5f9' } : null
                                                }
                                            >
                                                <List.Item.Meta
                                                    avatar={<HiOutlineOfficeBuilding className="ml-2" size="2em" />}
                                                    title={<strong>{list.name}</strong>}
                                                    style={{ cursor: 'pointer' }}
                                                    description={list.address}
                                                    onClick={() => {
                                                        selectedAccount(list)
                                                    }}
                                                />
                                                <div className="mr-2">{list.email}</div>
                                            </List.Item>
                                        ))
                                    )}
                                </ul>
                            </TabPane>
                        </Tabs>
                    </Modal>

                    <div className={`${style.topbar}`}>
                        <div className={`${style.clinicinfo}`}>
                            {userRole !== 'DOCTOR' && userRole !== 'STAFF' && userRole !== 'ADMIN' && userRole !== 'PHARMACYADMIN' && userRole !== 'COORDINATOR' ? (
                                <div className="d-flex align-items-center">
                                    <Button type="link" onClick={onAccountTypeChange} className="pt-2 ml-n3">
                                        <span className="selctedadmin">
                                            {`${selectedCompanyInfo?.name || 'change organization'}`}
                                        </span>
                                        <CaretDownOutlined />
                                    </Button>
                                    <div className="ml-3 d-flex align-items-center">
                                        <i className="icmn-location font-size-20 pr-2" />
                                        <Text level={4}>
                                            {selectedCompanyInfo?.address || 'No Company/Clinic is Selected'}
                                        </Text>
                                    </div>
                                </div>
                            ) : (
                                <div className="d-flex align-items-center">
                                    <Button type="link"  className="pt-2 ml-n3">
                                        <span className="selctedadmin">
                                            {`${selectedRole.CompanyDetails.Name }`}
                                        </span>
                                        
                                    </Button>
                                    <div className="ml-3 d-flex align-items-center">
                                            <i className="icmn-location font-size-20 pr-2" />
                                            <Text level={4}>
                                                {selectedRole.CompanyDetails.address || 'No Company/Clinic is Selected'}
                                            </Text>
                                        </div>
                                </div>
                                
                                    
                            )
                                }
                        </div>

                        <div className={`${style.topRight} row manageacct`}>
                            {userRole !== 'DOCTOR' && userRole !== 'STAFF' && userRole !== 'ADMIN' && userRole !== 'PHARMACYADMIN' && userRole !== 'COORDINATOR' ? (
                                <Button size="middle"
                                    className={`${style.btn_all}`}
                                    type="primary"
                                    onClick={manageAccount}>
                                    {' '}
                                    {`Manage Accounts`}
                                </Button>
                            ) : null}
                            {/* {userRole === 'STAFF' && (
                                <>
                                <Button size="middle" className="ml-n1">
                                    {' '}
                                    {`${loginUserName}`}
                                </Button>
                                <Button type="primary" className="ml-2">
                                    STAFF
                                </Button>
                                </>
                            )} */}
                            {userRole === 'DOCTOR' ? (
                                <>
                                    <div className="mr-4 d-none d-sm-flex align-items-center">
                                        <Text level={3}>
                                            <strong className="d-inline-block mr-3">Available for Walkin Call </strong>
                                        </Text>
                                        <Tooltip placement="top" title="Available for walkin?">
                                            <Switch
                                                style={{ backgroundColor: availableForWalkin ? 'green' : 'red' }}
                                                checkedChildren={<CheckOutlined />}
                                                unCheckedChildren={<CloseOutlined />}
                                                loading={updateWalkingAvaibilityLoader}
                                                defaultChecked={availableForWalkin}
                                                onChange={(e) => {
                                                    changeWalkingInAvailableStatus(e)
                                                }}
                                            />
                                        </Tooltip>
                                    </div>
                                </>
                            ) : null}
                            {/* <div className="mr-4 d-none d-sm-block pl-5">
                                <Actions />
                            </div> */}
                            <div>
                                <UserMenu userRoles={companyEmployee} userData={userData} />
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    )
}

export default connect(mapStateToProps)(TopBar)